<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              <!-- &nbsp;
              <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="get_list"
              /> -->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Manage Student Master</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column header="Action" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
          <Column header="Status" style="min-width: 8rem;text-align: center;">
            <template #body="{ data }">
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column header="Course" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.cource }}
            </template>
          </Column>

          <Column
            header="Enrollment No"
            style="min-width: 8rem"
            :field="columns[15]"
          >
            <template #body="{ data }">
              {{ data.enrollmenT_NO }}
            </template>
          </Column>
          
          <Column header="First Name" style="min-width: 8rem">
            <template #body="{ data }">
              {{ data.firsT_NAME }}
            </template>
          </Column>
          <Column header="Middle Name">
            <template #body="{ data }">
              {{ data.middeL_NAME }}
            </template>
          </Column>
          <Column header="Surname">
            <template #body="{ data }">
              {{ data.surname }}
            </template>
          </Column>
          <Column header="Adhar No."  style="text-align:right;">
            <template #body="{ data }">
              {{ data.aadhaR_CARD }}
            </template>
          </Column>

          <Column header="State">
            <template #body="{ data }">
              {{ data.state }}
            </template>
          </Column>
          <Column header="Disrtict">
            <template #body="{ data }">
              {{ data.disrtictname }}
            </template>
          </Column>
          <Column header="Taluka">
            <template #body="{ data }">
              {{ data.taluka }}
            </template>
          </Column>
          <Column header="Pincode"  style="text-align:right;">
            <template #body="{ data }">
              {{ data.pincode }}
            </template>
          </Column>
          <!-- <Column header="address">
            <template #body="{ data }">
              {{ data.address }}
            </template>
          </Column> -->
          <Column header="Mobile"  style="text-align:right;">
            <template #body="{ data }">
              {{ data.mobile }}
            </template>
          </Column>
          <Column header="Email">
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column header="Password">
            <template #body="slotProps">
              <Button
                class="p-button-rounded p-button-success p-button-outlined"
                @click="update_pass(slotProps.data)"
                >Update</Button
              >
            </template>
          </Column>

          <Column header="Year"  style="text-align:right;">
            <template #body="{ data }">
              {{ data.year }}
            </template>
          </Column>
          <Column header="Gender">
            <template #body="{ data }">
              {{ data.gender }}
            </template>
          </Column>
          <Column header="Category">
            <template #body="{ data }">
              {{ data.category }}
            </template>
          </Column>
          <Column header="Caste">
            <template #body="{ data }">
              {{ data.caste }}
            </template>
          </Column>
          <Column header="Family Income"  style="min-width: 8rem;text-align:right;" >
            <template #body="{ data }">
              {{ data.familY_INCOME }}
            </template>
          </Column>
          <Column header="Medium">
            <template #body="{ data }">
              {{ data.medium }}
            </template>
          </Column>
          <Column header="Board">
            <template #body="{ data }">
              {{ data.board }}
            </template>
          </Column>
          <Column header="Percentage" style="text-align:right;">
            <template #body="{ data }">
              {{ data.percentage }}
            </template>
          </Column>
          <Column header="Area">
            <template #body="{ data }">
              {{ data.area }}
            </template>
          </Column>
          <Column header="School District">
            <template #body="{ data }">
              {{ data.schooL_DISTRICT }}
            </template>
          </Column>
          <Column header="Date Of Birth" style="min-width: 8rem">
            <template #body="{ data }">
              {{ data.dob }}
            </template>
          </Column>
          <Column header="Application Status" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.applicatioN_STATUS }}
            </template>
          </Column>
          <Column header="Application Status By" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.applicatioN_STATUS_BY }}
            </template>
          </Column>
          <Column header="Application Status By PH" style="min-width: 12rem;text-align:right;">
            <template #body="{ data }">
              {{ data.applicatioN_STATUS_BY_PH }}
            </template>
          </Column>
          <Column header="Application Status On" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.applicatioN_STATUS_ON }}
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="visibleFull"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>Register Student Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <!-- --------------------------------------------------------------------------------------------- -->
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="Course">Course</label>
                  <Dropdown
                    id="status"
                    v-model="coursestatus"
                    :options="courseItems"
                    :value="courseItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="year">Year</label>
                  <InputText
                    id="year"
                    type="number"
                    v-model.trim="product.year"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.year }"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="enrollmenT_NO">Enrollement No</label>
                  <InputText
                    id="enrollmenT_NO"
                    type="text"
                    v-model.trim="product.enrollmenT_NO"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.enrollmenT_NO,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model.trim="product.email"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.email }"
                  />
                </div>

                <!-- --------------------------------------------------------------------------------------------- -->
                <div class="field col-12 md:col-2">
                  <label for="firsT_NAME">First Name</label>
                  <InputText
                    id="firsT_NAME"
                    type="text"
                    v-model.trim="product.firsT_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.firsT_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="middeL_NAME">Middle Name</label>
                  <InputText
                    id="middeL_NAME"
                    type="text"
                    v-model.trim="product.middeL_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.middeL_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="surname">Lastname</label>

                  <InputText
                    id="surname"
                    type="text"
                    v-model.trim="product.surname"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.surname }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="dob">Date Of Birth</label>
                  <Calendar
                    inputId="calendar"
                    v-model="product.dob"
                    id="dob"
                    required="true"
                    dateFormat="d-MM-yy"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.dob }"
                  ></Calendar>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="aadhaR_CARD">Adhar No</label>
                  <InputText
                    id="aadhaR_CARD"
                    type="number"
                    v-model.trim="product.aadhaR_CARD"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.aadhaR_CARD }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="lastname2">Gender</label>
                  <Dropdown
                    id="status"
                    v-model="gender_status"
                    :options="gender_dropdownItems"
                    :value="gender_dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !gender_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <!-- ------------------------------------------------------------------------------------------------- -->
                <div class="field col-12 md:col-2">
                  <label for="familY_INCOME">Family Income</label>
                  <InputText
                    id="familY_INCOME"
                    type="number"
                    v-model.trim="product.familY_INCOME"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.familY_INCOME,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.state }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="disrtict">District</label>
                  <Dropdown
                    id="status"
                    v-model="district_status"
                    :options="districtItems"
                    :value="districtItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !district_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="taluka">Taluka</label>
                  <InputText
                    id="taluka"
                    type="text"
                    v-model.trim="product.taluka"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.taluka }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="citY_VILLAGE">City/Village</label>
                  <InputText
                    id="citY_VILLAGE"
                    type="text"
                    v-model.trim="product.citY_VILLAGE"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.citY_VILLAGE }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="number"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.pincode }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <!-- ------------------------------------------------------------------------------------------------- -->
                <div class="field col-12">
                  <label for="address">Address</label>

                  <Textarea
                    id="address"
                    type="text"
                    v-model.trim="product.address"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.address }"
                  />
                </div>
                <!-- ------------------------------------------------------------------------------------------------- -->
                <div class="field col-12 md:col-2">
                  <label for="firstname2">Area</label>
                  <Dropdown
                    id="status"
                    v-model="area_status"
                    :options="area_dropdownItems"
                    :value="area_dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !area_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="lastname2">Category</label>
                  <Dropdown
                    id="cat_status"
                    v-model="cat_status"
                    :options="catItems"
                    :value="catItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !cat_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="caste">Cast</label>
                  <InputText
                    id="caste"
                    type="text"
                    v-model.trim="product.caste"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.caste,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="lastname2">Creamylayer</label>
                  <Dropdown
                    id="status"
                    v-model="creamylayer_status"
                    :options="creamylayer_dropdownItems"
                    :value="creamylayer_dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !creamylayer_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="schooL_DISTRICT">School District</label>
                  <InputText
                    id="schooL_DISTRICT"
                    type="text"
                    v-model.trim="product.schooL_DISTRICT"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.schooL_DISTRICT,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="lastname2">Board</label>
                  <Dropdown
                    id="status"
                    v-model="board_status"
                    :options="board_dropdownItems"
                    :value="board_dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !board_status }"
                    required="true"
                  ></Dropdown>
                </div>
                <!-- ------------------------------------------------------------------------------------------------- -->
                <div class="field col-12 md:col-2">
                  <label for="medium">Medium</label>
                  <InputText
                    id="medium"
                    type="text"
                    v-model.trim="product.medium"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.medium,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="percentage">Percentage</label>
                  <InputText
                    id="percentage"
                    type="text"
                    v-model.trim="product.percentage"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.percentage,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="height">Height</label>
                  <InputText
                    id="height"
                    type="number"
                    v-model.trim="product.height"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.height,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="normaL_CHEST">Normal Chest</label>
                  <InputText
                    id="normaL_CHEST"
                    type="number"
                    v-model.trim="product.normaL_CHEST"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.normaL_CHEST,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pumpeD_CHEST">Pumped Chest</label>
                  <InputText
                    id="pumpeD_CHEST"
                    type="number"
                    v-model.trim="product.pumpeD_CHEST"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.pumpeD_CHEST,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="mobile">Mobile No</label>
                  <InputText
                    id="mobile"
                    type="number"
                    v-model.trim="product.mobile"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.mobile,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <Button
              v-if="product.studenT_ID"
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="Addstudent"
            />
            <Button
              v-else
              label="ADD"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="Addstudent"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-danger mr-1 mb-1"
              @click="visibleFull = false"
            />
          </div>

          <!-- ----------------------------------------------- application status------------------------------------------------------------------------------------------- -->
          <div class="col-12">
            <div class="card">
              <h5>Application Status</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <DataTable class="mt-5" :value="Application_product">
                    <Column
                      field="applicatioN_STATUS"
                      header="Application Status"
                      :style="{ width: '150px' }"
                      frozen
                    ></Column>
                    <Column
                      field="applicatioN_STATUS_BY"
                      header="Application Status BY"
                      :style="{ width: '150px' }"
                      frozen
                    ></Column>
                    <Column
                      field="applicatioN_STATUS_BY_PH"
                      header="Application Status By PH"
                      :style="{ width: '150px' }"
                      frozen
                    ></Column>
                    <Column
                      field="applicatioN_STATUS_ON"
                      header="Application Status On"
                      :style="{ width: '150px' }"
                      frozen
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          <!-- ---------------------------------------upload documents-------------------------------------------------------------------------------- -->

          <div class="col-12" v-if="product.studenT_ID">
            <div class="card">
              <h5>Upload Documents</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12">

                  <table>
                    <tr>
                      <td width="4%">Id</td>
                      <td width="40%">Name</td>
                      
                      <td width="40%">Path</td>
                      <td width="30%">Upload</td>
                      <!-- <td>view</td> -->
                    

                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Aadhar Card</td>
                      <td><FileUpload
                          mode="basic"
                          id="file_attachment_adhar"
                          ref="file_attachment_adhar"
                          @change="handleFileUploadadhar"
                          name="demo[]"
                          accept="application/pdf,image/jpeg,image/png"
                          :maxFileSize="1000000"
                        /></td>
                        <td><Button
                    v-if="file_attachment_adhar"
                    label="Upload"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="upload_file(1)"
                  /></td>
                  <!-- <td><a  :href="doc_master[0].document[0].link" target="_blank">view
              <img
                :src="doc_master[0].document[0].link"
                :alt="doc_master[0].document[0].link"
                class="shadow-2"
                width="100"

                
              />
              </a></td>   -->
                  <!-- {{doc_master[0].document[0].link}} -->
                  

                    </tr>
                     <tr>
                      <td>2</td>
                      <td>Caste Cetificate</td>
                      <td><FileUpload
                          mode="basic"
                          id="file_attachment_cast"
                          ref="file_attachment_cast"
                          @change="handleFileUploadcast"
                          name="demo[]"
                          accept="application/pdf,image/jpeg,image/png"
                          :maxFileSize="1000000"
                        /></td>
                        <td><Button
                    v-if="file_attachment_cast"
                    label="Upload"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="upload_file(2)"
                  /></td>

                    </tr>
                     <tr>
                      <td>3</td>
                      <td>10th Mark Sheet</td>
                      <td><FileUpload
                          mode="basic"
                          id="file_attachment_mark"
                          ref="file_attachment_mark"
                          @change="handleFileUploadmark"
                          name="demo[]"
                          accept="application/pdf,image/jpeg,image/png"
                          :maxFileSize="1000000"
                        /></td>
                        <td><Button
                    v-if="file_attachment_mark"
                    label="Upload"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="upload_file(3)"
                  /></td>

                    </tr>
                     <tr>
                      <td>4</td>
                      <td>TC</td>
                      <td><FileUpload
                          mode="basic"
                          id="file_attachment_tc"
                          ref="file_attachment_tc"
                          @change="handleFileUploadtc"
                          name="demo[]"
                          accept="application/pdf,image/jpeg,image/png"
                          :maxFileSize="1000000"
                        /></td>
                        <td><Button
                    v-if="file_attachment_tc"
                    label="Upload"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="upload_file(4)"
                  /></td>

                    </tr>
                     <tr>
                      <td>5</td>
                      <td>Photo</td>
                      <td><FileUpload
                          mode="basic"
                          id="file_attachment_photo"
                          ref="file_attachment_photo"
                          @change="handleFileUploadphoto"
                          name="demo[]"
                          accept="application/pdf,image/jpeg,image/png"
                          :maxFileSize="1000000"
                        /></td>
                        <td><Button
                    v-if="file_attachment_photo"
                    label="Upload"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="upload_file(5)"
                  /></td>

                    </tr>
                  </table>
                  
                </div>
                
              </div>
            </div>
          </div>

          <!-- ---------------------------------------upload documents-------------------------------------------------------------------------------- -->
        </Sidebar>

        <Dialog
          v-model:visible="update_pass_Dialog"
          :style="{ width: '450px', width: '450px' }"
          header="Update Password"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="enrollmenT_NO">Enrollement No</label>
                <InputText
                  id="enrollmenT_NO"
                  v-model.trim="product.enrollmenT_NO"
                  required="true"
                  autofocus
                  disabled
                  :class="{ 'p-invalid': submitted && !product.enrollmenT_NO }"
                />
              </div>
              <div class="field">
                <label for="mobile">Mobile</label>
                <InputText
                  id="mobile"
                  v-model.trim="product.mobile"
                  required="true"
                  autofocus
                  disabled
                  :class="{ 'p-invalid': submitted && !product.mobile }"
                />
              </div>
              <div class="field">
                <label for="mobile">Password</label>
                <InputText
                  id="mobile"
                  v-model.trim="password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !password }"
                />
              </div>
              <div class="field">
                <label for="mobile">Password</label>
                <InputText
                  id="mobile"
                  v-model.trim="conf_password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !conf_password }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.studenT_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="update_password"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="update_pass_Dialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="productDialog"
          header="Student Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field"></div>
              <div class="field">
                <label for="batch">Course</label>
                <InputText
                  id="batch"
                  v-model.trim="product.batch"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.batch }"
                />
              </div>
              <div class="field">
                <label for="name">Name</label>
                <InputText
                  id="name"
                  v-model.trim="product.name"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.name }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.mjmB_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addstudent"
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addstudent"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.firsT_NAME }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deletestudent"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      password: "",
      conf_password: "",
      doc_master: [],
      Application_product: [],
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,

      file_attachment: "",
      file_attachment_adhar:"",
      file_attachment_cast:"",
      file_attachment_mark:"",
      file_attachment_tc:"",
      file_attachment_photo:"",


      selectstatus: { name: "", value: "" },
      gender_status: { name: "", value: "" },
      coursestatus: { name: "", value: "" },
      area_status: { name: "", value: "" },
      district_status: { name: "", value: "" },
      cat_status: { name: "", value: "" },
      creamylayer_status: { name: "", value: "" },
      board_status: { name: "", value: "" },

      gender_dropdownItems: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
      ],
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      courseItems: [],
      area_dropdownItems: [
        { name: "Ruler", value: "Ruler" },
        { name: "Urban", value: "Urban" },
      ],
      districtItems: [],
      catItems: [],
      creamylayer_dropdownItems: [
        { name: "Creamylayer", value: "Creamylayer" },
        { name: "Non-Creamylayer", value: "Non-Creamylayer" },
      ],
      board_dropdownItems: [
        { name: "State", value: "State" },
        { name: "CBSC", value: "CBSC" },
        { name: "ICSE", value: "ICSE" },
      ],

      products: null,
      visibleFull: false,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,

      limit: 10,
      columns: [],
      update_pass_Dialog: false,
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_course_list();
    this.get_district_list();
    this.get_category_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    // open_doc(data)
    // {
    //   window.open(data, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    // },
    handleFileUploadadhar() {this.file_attachment_adhar = this.$refs.file_attachment_adhar.files[0];},
    handleFileUploadcast() {this.file_attachment_cast = this.$refs.file_attachment_cast.files[0];},
    handleFileUploadmark() {this.file_attachment_mark = this.$refs.file_attachment_mark.files[0];},
    handleFileUploadtc() {this.file_attachment_tc = this.$refs.file_attachment_tc.files[0];},
    handleFileUploadphoto() {this.file_attachment_photo = this.$refs.file_attachment_photo.files[0];},

    upload_file(type) {
      var data="";
      var file="";
      var file_name="";
      var file_type="";
      var d2 = new Date();
      if (type==1) {

                      file=this.file_attachment_adhar;
                      file_name=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachment_adhar.name;
                      file_type=this.file_attachment_adhar.type;


                     data  = {
                                "DOC_TYPE":"Aadhar Card",
                                "DOC_NAME":file_name,
                                "STUDENT_ID":this.product.studenT_ID
                                };
                      
      } 
      else if(type==2) {
                      file=this.file_attachment_cast;
                     file_name=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachment_cast.name;
                      file_type=this.file_attachment_cast.type;


                     data  = {
                                "DOC_TYPE":"Caste Cetificate",
                                "DOC_NAME":file_name,
                                "STUDENT_ID":this.product.studenT_ID
                                };
                      
        
      }
      else if(type==3) {

                      file=this.file_attachment_mark;
                     file_name=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachment_mark.name;
                      file_type=this.file_attachment_mark.type;


                    data  = {
                                "DOC_TYPE":"10th Mark Sheet",
                                "DOC_NAME":this.file_attachment_mark.name,
                                "STUDENT_ID":this.product.studenT_ID
                                };
                      
        
      }
      else if(type==4) {

                      file=this.file_attachment_tc;
                      file_name=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachment_tc.name;
                      file_type=this.file_attachment_tc.type;


                    data  = {
                                "DOC_TYPE":"TC",
                                "DOC_NAME":file_name,
                                "STUDENT_ID":this.product.studenT_ID
                                };

                      
        
      }
      else if(type==5) {

                      file=this.file_attachment_photo;
                     file_name=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachment_photo.name;
                      file_type=this.file_attachment_photo.type;


                    data  = {
                                "DOC_TYPE":"Photo",
                                "DOC_NAME":file_name,
                                "STUDENT_ID":this.product.studenT_ID
                                };
                      
        
      }
      else
      {
        alert("ERROR");
        
      }


      this.isLoadingModel = true;
        var promise = apis.uploadstudentdocuments(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (file_name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/studentdocument/" + file_name,
                //       contentType: file_type,
                //     }
                //   )

                var file_data={
                  filePath:"studentdocument/" + file_name,
                  contentType: file_type,

                }
                var promise5 = apis.uploadFile(file_data);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": file_type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data.message);

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;
                this.get_doc_list(this.product.mobile);
                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data.message);
              // this.hideDialog1();
              this.productDialog = false;
              this.get_doc_list(this.product.mobile);
              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });


      
    },




    update_password() {
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Update Mobile NO",
          life: 3000,
        });
        return false;
      }
      if (!this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter password",
          life: 3000,
        });
        return false;
      }
      if (!this.conf_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Confirm Password",
          life: 3000,
        });
        return false;
      }
      if (this.conf_password != this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Confirm Password Not Match With Password",
          life: 3000,
        });
        return false;
      }

      //api
      var data = {
        username: this.product.mobile,
        password: this.password,
        type: "student",
      };
      this.loading = true;
      var promise = apis.passwordreset(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.$swal(response.data.message);
        this.update_pass_Dialog = false;
      });
    },
    update_pass(product) {
      this.password = "";
      this.conf_password = "";
      this.product = { ...product };
      this.update_pass_Dialog = true;
    },
    get_doc_list: function (mobile) {
      var data = {
        MOBILE: mobile,
        //"MOBILE":"9096799390"
      };
      this.loading = true;
      var promise = apis.getdocmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        this.doc_master = response.data;
      });
    },
    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
     
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },
    get_district_list: function () {
      var data = {
        Limit: 100,

        count: false,
      };
     
      var promise = apis.getdistrictmaster(data, this.id);
      promise.then((response) => {
        

        var info = response.data;
        if (info) {
          this.districtItems = [];
          for (var i = 0; i < info.length; i++) {
            this.districtItems.push({
              name: info[i].district,
              value: info[i].districT_ID,
            });
          }
        }
      });
    },
    get_category_list: function () {
      var data = {
        Limit: 100,

        count: false,
      };
      
      var promise = apis.getcatmaster(data, this.id);
      promise.then((response) => {
        

        var info = response.data;
        if (info) {
          this.catItems = [];
          for (var i = 0; i < info.length; i++) {
            this.catItems.push({
              name: info[i].category,
              value: info[i].category,
            });
          }
        }
      });
    },

    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      //await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.studentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.studentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.Application_product = [];
      this.submitted = false;
      //this.productDialog = true;

      this.visibleFull = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Addstudent() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Status",
          life: 3000,
        });
        return false;
      }

      if (!this.coursestatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Course",
          life: 3000,
        });
        return false;
      }
      if (!this.product.year) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Year",
          life: 3000,
        });
        return false;
      }
      if (!this.product.enrollmenT_NO) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Enrollement No",
          life: 3000,
        });
        return false;
      }
      if (!this.product.email) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter email",
          life: 3000,
        });
        return false;
      }

      if (!this.product.firsT_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter First Name",
          life: 3000,
        });
        return false;
      }

      

      if (!this.product.middeL_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Middle Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.surname) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Lastname",
          life: 3000,
        });
        return false;
      }
      
      if (!this.product.dob) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Date Of Birth",
          life: 3000,
        });
        return false;
      }
      if (!this.product.aadhaR_CARD) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Adhar No",
          life: 3000,
        });
        return false;
      }
      
      if (!this.gender_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Gender",
          life: 3000,
        });
        return false;
      }
      if (!this.product.familY_INCOME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Family Income",
          life: 3000,
        });
        return false;
      }
      if (!this.product.state) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter state",
          life: 3000,
        });
        return false;
      }
      if (!this.district_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter District",
          life: 3000,
        });
        return false;
      }

      if (!this.product.taluka) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Taluka",
          life: 3000,
        });
        return false;
      }
      if (!this.product.citY_VILLAGE) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter City/Village",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pincode) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pincode",
          life: 3000,
        });
        return false;
      }

       if (!this.product.address) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Address",
          life: 3000,
        });
        return false;
      }

      if (!this.area_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Area",
          life: 3000,
        });
        return false;
      }

      if (!this.cat_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Category",
          life: 3000,
        });
        return false;
      }

      if (!this.product.caste) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Cast",
          life: 3000,
        });
        return false;
      }
      if (!this.creamylayer_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Creamylayer",
          life: 3000,
        });
        return false;
      }

      if (!this.product.schooL_DISTRICT) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter School District",
          life: 3000,
        });
        return false;
      }

      if (!this.board_status.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Board",
          life: 3000,
        });
        return false;
      }

      if (!this.product.medium) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Medium",
          life: 3000,
        });
        return false;
      }
       if (!this.product.percentage) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Percentage",
          life: 3000,
        });
        return false;
      }
       if (!this.product.height) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Height",
          life: 3000,
        });
        return false;
      }
       if (!this.product.normaL_CHEST) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Normal Chest",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pumpeD_CHEST) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pumped Chest",
          life: 3000,
        });
        return false;
      }
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Mobile No",
          life: 3000,
        });
        return false;
      }

      var from = new Date(this.product.dob);
      var dob =
        from.toLocaleDateString("en-US", { day: "numeric" }) +
        "-" +
        from.toLocaleDateString("en-US", { month: "short" }) +
        "-" +
        from.toLocaleDateString("en-US", { year: "2-digit" });
      //edit
      if (this.product.studenT_ID) {
        var data = {
          Student_ID: this.product.studenT_ID,
          firsT_NAME: this.product.firsT_NAME,
          mobile: this.product.mobile,
          password: this.product.password,
          disrtict: this.district_status.value.toString(),
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          taluka: this.product.taluka,
          email: this.product.email,
          pincode: this.product.pincode,
          aadhaR_CARD: this.product.aadhaR_CARD,
          status: this.selectstatus.value,
          batch: this.coursestatus.value,
          enrollmenT_NO: this.product.enrollmenT_NO,

          year: this.product.year,
          gender: this.gender_status.value,
          category: this.cat_status.value,
          caste: this.product.caste,
          familY_INCOME: this.product.familY_INCOME,
          medium: this.product.medium,
          board: this.board_status.value,
          percentage: this.product.percentage,
          area: this.area_status.value,
          schooL_DISTRICT: this.product.schooL_DISTRICT,
          dob: dob,
          applicatioN_STATUS: this.product.applicatioN_STATUS,
          applicatioN_STATUS_BY: this.product.applicatioN_STATUS_BY,
          applicatioN_STATUS_BY_PH: this.product.applicatioN_STATUS_BY_PH,
          applicatioN_STATUS_ON: this.product.applicatioN_STATUS_ON,
          height: this.product.height,
          normaL_CHEST: this.product.normaL_CHEST,
          pumpeD_CHEST: this.product.pumpeD_CHEST,
          creamylayer: this.creamylayer_status.value,
          dummY_ID: this.product.dummY_ID,
          mjyotI_ID: this.product.mjyotI_ID,
          shortlisted: this.product.shortlisted,

          createD_ON: "03-MAR-22 01.30.49.560000000 PM",
          createD_BY: localStorage.getItem("full_name"),
          updateD_ON: "03-MAR-22 01.31.04.076000000 PM",
          updateD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promise = apis.editstudentmaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          firsT_NAME: this.product.firsT_NAME,
          mobile: this.product.mobile,
          password: this.product.password,
          disrtict: this.district_status.value.toString(),
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          taluka: this.product.taluka,
          email: this.product.email,
          pincode: this.product.pincode,
          aadhaR_CARD: this.product.aadhaR_CARD,
          status: this.selectstatus.value,
          batch: this.coursestatus.value,
          enrollmenT_NO: this.product.enrollmenT_NO,

          year: this.product.year,
          gender: this.gender_status.value,
          category: this.cat_status.value,
          caste: this.product.caste,
          familY_INCOME: this.product.familY_INCOME,
          medium: this.product.medium,
          board: this.board_status.value,
          percentage: this.product.percentage,
          area: this.area_status.value,
          schooL_DISTRICT: this.product.schooL_DISTRICT,
          dob: dob,
          applicatioN_STATUS: this.product.applicatioN_STATUS,
          applicatioN_STATUS_BY: this.product.applicatioN_STATUS_BY,
          applicatioN_STATUS_BY_PH: this.product.applicatioN_STATUS_BY_PH,
          applicatioN_STATUS_ON: this.product.applicatioN_STATUS_ON,
          height: this.product.height,
          normaL_CHEST: this.product.normaL_CHEST,
          pumpeD_CHEST: this.product.pumpeD_CHEST,
          creamylayer: this.creamylayer_status.value,
          dummY_ID: this.product.dummY_ID,
          mjyotI_ID: this.product.mjyotI_ID,
          shortlisted: this.product.shortlisted,

          createD_ON: "03-MAR-22 01.30.49.560000000 PM",
          createD_BY: localStorage.getItem("full_name"),
          updateD_ON: "03-MAR-22 01.31.04.076000000 PM",
          updateD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promises = apis.addstudentmaster(data1);
        promises
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      this.gender_status = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment= "",
      this.file_attachment_adhar="",
      this.file_attachment_cast="",
      this.file_attachment_mark="",
      this.file_attachment_tc="",
      this.file_attachment_photo="",

      this.Application_product = [];
      this.file_attachment = "";
      this.product = { ...product };
      // this.productDialog = true;

      this.Application_product.push(this.product);

      //status
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;
      //gender
      this.gender_status.value = this.product.gender;
      this.gender_status.name = this.product.gender;
      //area
      this.area_status.value = this.product.area;
      this.area_status.name = this.product.area;

      //course
      //this.coursestatus.value = this.product.gender;
      this.coursestatus.value = this.product.batch;
      this.coursestatus.name = this.product.cource;

      //district
      this.district_status.name = this.product.disrtictname;
      this.district_status.value = parseInt(this.product.disrtict);

      //category
      this.cat_status.value = this.product.category;
      this.cat_status.name = this.product.category;

      //Creamylayer
      this.creamylayer_status.value = this.product.creamylayer;
      this.creamylayer_status.name = this.product.creamylayer;

      //board
      this.board_status.value = this.product.board;
      this.board_status.name = this.product.board;

      //get std document list
      this.get_doc_list(this.product.mobile);

      // this.file_attachment = this.product.image;
      this.visibleFull = true;
    },
    confirmDeleteProduct(product) {
      this.product = { ...product };
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deletestudent() {
      if (this.product.studenT_ID) {
        var data = {
          Student_id: this.product.studenT_ID,
        };
        this.isLoadingModel = true;
        var promise = apis.deletestudentmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Student");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
